<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="showModal"
    >   
        <CForm>
            <CRow>
              <CCol md="12">
                <CInput
                  label="Nombre:"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="given-name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CInput
                  label="Teléfono:"
                  :lazy="false"
                  :value.sync="$v.form.phone.$model"
                  :isValid="checkIfValid('phone')"
                  placeholder=""
                  autocomplete="given-phone"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
              <CCol md="6">
                <CInput
                  label="Correo Electrónico:"
                  :lazy="false"
                  :value.sync="$v.form.email.$model"
                  :isValid="checkIfValid('email')"
                  placeholder=""
                  autocomplete="given-email"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CSelect
                  label="Departamento: "
                  :value.sync="$v.form.departament_id.$model"
                  :options="departamentsOptions"
                />
              </CCol>
              <CCol md="6">
                <CSelect
                  label="Lugar: "
                  :value.sync="$v.form.location_id.$model"
                  :options="locationsOptions"
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import asset_locations from '../../services/asset_locations';
import asset_departaments from '../../services/asset_departaments';

export default {
  name: 'AssetPersonModal',
  props: {

  },
  data () {
    return {
      showModal: false,
      title: "Nueva Persona",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      departaments: [],
      departamentsOptions: [],
      locations: [],
      locationsOptions: []
    }
  },
  mounted: async function() {
    let response = await asset_locations.get(); 

    if(response.type == "success"){
      this.locations = response.data;
    }

    this.locationsOptions = await this.$parseSelectOptionsOrdered(this.locations, "id", "name",[{"label": "","value":""}]);

    let response2 = await asset_departaments.get(); 

    if(response2.type == "success"){
      this.departaments = response2.data;
    }

    this.departamentsOptions = await this.$parseSelectOptionsOrdered(this.departaments, "id", "name",[{"label": "","value":""}]);
  },
  methods: {
    storeModal () {
      this.showModal = true;
      this.color = "info";
      this.title = 'Nueva Persona';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: 0,
          departament_id: '',
          location_id: '',
          name: '',
          phone: '',
          email: ''
      }
    },
    updateModal (item) {
      this.showModal = true;
      this.color = "warning";
      this.title = 'Editar Persona';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
          id: item.id,
          name: item.name,
          phone: item.phone,
          email: item.email,
          departament_id: item.asset_departament_id,
          location_id: item.asset_location_id
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          departament_id: '',
          location_id: '',
          name: '',
          phone: '',
          email: ''
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      phone: {},
      email: {},
      departament_id: {},
      location_id: {}
    }
  },
}
</script>