<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{caption}}
        <div class="card-header-actions">
          <CButton color="info" size="sm" @click="storeModal()">Nueva Persona</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
      >
        <template #name="{item}"><td class="text-left"><b>{{item.name}}</b><br /><span style="font-size: 12px;">{{item.departament}}<br />{{item.location}}</span></td></template>
        <template #phone="{item}"><td class="text-center w200" style="font-size: 12px;">{{item.phone}}</td></template>
        <template #email="{item}"><td class="text-center w300" style="font-size: 12px;">{{item.email}}</td></template>
        <template #actions="{item}">
          <td class="table_actions">
            <CButton color="warning" size="sm" @click="updateModal(item)"><CIcon name="cil-pencil"/></CButton>
            <CButton color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <AssetPersonModal ref="addModal" @store="storeProcess" @update="updateProcess"></AssetPersonModal>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import AssetPersonModal from './AssetPersonModal.vue'
import DeleteModal from '../global/DeleteModal.vue'
import ws from '../../services/asset_persons';
import store from '../../store'

export default {
  name: 'AssetPersonsTable',
  components: { AssetPersonModal, DeleteModal },
  props: {
    items: Array,
    permissions: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'name', label: 'Nombre'},
          {key: 'phone', label: 'Teléfono'},
          {key: 'email', label: 'Correo Electrónico'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: [],
    }
  },
  methods: {
    storeModal () {
      this.$refs.addModal.storeModal();
    },
    updateModal (item) {
      this.$refs.addModal.updateModal(item);
    },
    deleteModal (item) {
      this.$refs.deleteModal.show("Eliminar Persona", item.id, item.name);
    },
    async storeProcess(data) {
      let response = await ws.store(data);
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async updateProcess(data) {
      let response = await ws.update(data);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async deleteProcess(id) {
      let response = await ws.delete({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
